<template>
  <div v-if="counterparties">
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false" v-if="counterparties !== null" />
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list" >
        <router-link 
          tag="li"
          :key="index"
          v-for="(counterparties, index) in counterpartiesNew"
          :id="counterparties.id"
          :to = "{name: 'СounterpartiesCreateId', params: {id: counterparties.id}}" 
        >
          <a style="display: block">
            <h4>{{counterparties.name}}</h4>
            <b>{{counterparties.entity_physical_person_name ? counterparties.entity_physical_person_name : '-'}}</b>
            <p style="margin-top: 5px">{{counterparties.group_name ? counterparties.group_name : '-'}}</p>
            <div class="icon icon-arrow-right"></div>
          </a>
        </router-link >
      </ul>
      <router-link v-if="$root.add.can_add_contractor" to="/counterparties/new" class="add-plus"></router-link>
      <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
  </div>
</template>





<script>
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"

export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Довідник: Контрагенти', info: ''}
    ],

    filter: {
      flag: true,
      mod: 'counterparties'
    },
    btnFilter: false,
    counterparties: null,
    counterpartiesNew: [],
    arrFilters: '',

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    quantityList: '',
    listNot: false
  }),


  async created() {
    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)


    //title
    this.globalTitle[0].title = this.$root.langText.directory.counterpartiesDirection;
    this.$root.title = this.globalTitle


    const res = await this.$store.dispatch('postFiltersСounterparties', {params: this.params, data: this.arrFilters})
    this.counterparties = res.data;
    if(this.counterparties){
      this.counterpartiesNew = res.data.list
      this.quantityList = res.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    }
  },
  methods: {

    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('postFiltersСounterparties', {params: this.params, data: this.arrFilters})
      this.counterpartiesNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },



    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }
  },

  
  components: {
    Search, Filters, Pagination, SelectedFilters
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.counterparties)
    }
  },
}

</script>